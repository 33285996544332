import $ from 'jquery'

$(function() {
  const urlParams = new URLSearchParams(window.location.search);
  const signUp = urlParams.get('sign_up');
  const source = urlParams.get('source');

  if (signUp === 'success' && source) {
    dataLayer.push({
      event: 'sign_up',
      sign_up_flow: source
    })
    const newUrl = window.location.href.replace(/(\?|&)sign_up=success/, '').replace(/(\?|&)source=[^&]*/, '');
    window.history.replaceState({}, document.title, newUrl);
    localStorage.removeItem('user_source');
  }

  if ($('#user_source').length) {
    let userSource = localStorage.getItem('user_source');

    if (!userSource) {
      localStorage.setItem('user_source', $('#user_source').val());
      userSource = $('#user_source').val();
    } else {
      $('#user_source').val(userSource);
    }

    if (!window.location.search.includes('source')) {
      const newUrl = `${window.location.href}${window.location.search ? '&' : '?'}source=${userSource}`;
      window.history.replaceState({}, document.title, newUrl);
    }
  }
});
