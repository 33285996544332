import $ from 'jquery'

window.addEventListener('DOMContentLoaded', () => {
  const notDisplayCases = [
    'household/individual', 'individual/household',
    'individual', 'household', 'pleaseselectone'
  ]
  const selectField = $('.user_profiles_organization_type').find(
    '.bootstrapForm_input select',
  )
  const restrictedOrganizations = document.querySelectorAll(
    '[data-global-name="individual/household"]',
  )
  const arrayOfRestricted = Array.from(
    restrictedOrganizations,
    (option) => option.value,
  )

  const toggleSicCodeFieldVisibility = (selectedText) => {
    const text = selectedText.toLowerCase().replace(/\s/g, '')
    const shouldShow = !notDisplayCases.includes(text)
    $('.user_profiles_sic_code').parent().toggle(shouldShow)
  }

  const handleOrganizationSelect = (e) => {
    const selectedText = $(e.currentTarget).find(':selected').text()
    const selectedValue = $(e.currentTarget).find(':selected').val()
    const genderId = $('.user_gender_id')
    const ageRangeId = $('.user_profiles_age_range_id')

    if (arrayOfRestricted.includes(selectedValue)) {
      genderId.show()
      ageRangeId.show()
    } else {
      genderId.hide()
      ageRangeId.hide()
      resetSelectFields(genderId, ageRangeId)
    }

    toggleSicCodeFieldVisibility(selectedText)
  }

  const resetSelectFields = (...fields) => {
    fields.forEach((field) => field.find('select').val(null).trigger('change'))
  }

  // Setup event handlers
  $('.user_gender_id, .user_profiles_age_range_id').hide()
  toggleSicCodeFieldVisibility(selectField.find(':selected').text())
  selectField.on('change', handleOrganizationSelect)
})
